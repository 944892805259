import { gql } from 'urql';

export const collectionDetailsCombinedQuery = gql`
  query ($address: String!) {
    collectionData(address: $address) {
      collection
      entity
      mintWithLinked
      price
      maxPurchase
      start
      end
      checkInNeeded
      maxMint
      offerType
      passport
      minRewardBalance
      minVisits
      minFriendVisits
    }
    collectionMetadata(address: $address) {
      collection
      name
      symbol
      dataURI
      isActive
      areaPoints
      areaRadius
      linkedCollections
      collectionType
    }
    collectionDataAdditional(address: $address) {
      collection
      maxBalance
      mintWithLinkedOnly
      isCoupon
      mintModel
    }
  }
`;

export const userOwnedCollectionsQuery = gql`
  query ($user: String!, $collectionType: Float) {
    userOwnedCollections(user: $user, collectionType: $collectionType) {
      collection
      from
      to
      tokenId
      blockNumber
      transactionHash
      transactionIndex
      chainId
    }
  }
`;

export const whitelistedCollectionsQuery = gql`
  query ($address: String!) {
    whitelistedCollectionsForPassport(passport: $address) {
      whitelistedCollections {
        source
        chainId
      }
    }
  }
`;

export const tokenOwnershipQuery = gql`
  query ($user: String!, $collectible: String!) {
    userOwnedCollectibles(user: $user, collectible: $collectible) {
      ownedCollectibles {
        blockNumber
        timestamp
        tokenId
        transactionIndex
        collection
        from
        to
        transactionHash
      }
    }
  }
`;

export const subscriptionPriceQuery = gql`
  query ($collections: [String!]!) {
    subscriptionManagerPrices(collections: $collections) {
      collection
      price
    }
  }
`;

import { Client, createRequest, fetchExchange } from 'urql';
import {
  collectionDetailsCombinedQuery,
  subscriptionPriceQuery,
  tokenOwnershipQuery,
  userOwnedCollectionsQuery,
  whitelistedCollectionsQuery,
} from '../graphQLQueries/collection';
import { LogToLoot8Console } from './Loot8ConsoleLogger';
import { userSubscriptionsQuery, usersDataQuery } from '../graphQLQueries/users';
import { entityDataQuery } from '../graphQLQueries/entity';
import { getAppConfiguration } from '../appconstants';
import { marketplaceListingsDataQuery } from '../graphQLQueries/marketplace';
import { expassEventsDataQuery } from '../graphQLQueries/events';

let indexerServiceURL;

const fetchData = async (query: any, params: any) => {
  if (!indexerServiceURL || indexerServiceURL === null || indexerServiceURL === '') {
    let appConfig;
    try {
      appConfig = await getAppConfiguration();
    } catch (err) {
      LogToLoot8Console('GraphQL Helper - fetchData: Error while reading app config');
    }
    if (appConfig && appConfig.indexerService && appConfig.indexerService.baseUrl) {
      indexerServiceURL = appConfig.indexerService.baseUrl;
    }
  }

  // https://formidable.com/open-source/urql/docs/basics/react-preact/
  const client = new Client({
    url: `${indexerServiceURL}/graphql`,
    exchanges: [fetchExchange],
  });

  const request = createRequest(query, {
    ...params,
  });
  try {
    const response = await client.executeQuery(request);
    return response?.data ?? null;
  } catch (e) {
    LogToLoot8Console('Error while fetching GraphQL Data', e);
    return null;
  }
};

export const fetchCollectionDetailsCombined = async (
  collectionAddress: string,
): Promise<{
  collectionDataAdditional: CollectionDataAdditional;
  collectionMetadata: CollectionMetadata;
  collectionData: CollectionData;
} | null> => {
  const responseData = await fetchData(collectionDetailsCombinedQuery, {
    address: collectionAddress,
  });
  return responseData
    ? {
        collectionDataAdditional: responseData?.collectionDataAdditional ?? null,
        collectionMetadata: responseData?.collectionMetadata ?? null,
        collectionData: responseData?.collectionData ?? null,
      }
    : null;
};

export const fetchWhitelistedCollections = async (
  passportAddress: string,
): Promise<WhitelistedCollections[] | null> => {
  const responseData = await fetchData(whitelistedCollectionsQuery, {
    address: passportAddress,
  });
  return responseData?.whitelistedCollectionsForPassport?.whitelistedCollections ?? null;
};

export const fetchUsersData = async (page: number, pageSize: number): Promise<IUsers | null> => {
  const responseData = await fetchData(usersDataQuery, {
    page: page,
    pageSize: pageSize,
  });
  return responseData?.users ?? null;
};

export const fetchUserSubscriptionsEvents = async (
  user: string,
  collection: string = null,
): Promise<IUserSubscriptions[] | null> => {
  try {
    const responseData = await fetchData(userSubscriptionsQuery, {
      user,
      collection,
    });
    return responseData?.subscriptionManagerTradeEvents?.records ?? [];
  } catch (error) {
    return [];
  }
};

export const fetchUserOwnedCollectoinsData = async (
  user: string,
  collectionType: number,
): Promise<ICollectibleTransferEvent[] | null> => {
  const responseData = await fetchData(userOwnedCollectionsQuery, {
    user: user,
    collectionType: collectionType,
  });
  return responseData?.userOwnedCollections ?? null;
};

export const fetchAllEntityDetails = async (isActive: boolean): Promise<EntityDetails[] | null> => {
  const responseData = await fetchData(entityDataQuery, { isActive: isActive });
  return responseData?.entitiesData?.records ?? null;
};

export const fetchTokenOwner = async (user: string, collectible: string): Promise<TokenOwnership[] | null> => {
  const responseData = await fetchData(tokenOwnershipQuery, { user, collectible });
  return responseData?.userOwnedCollectibles?.ownedCollectibles ?? [];
};

export const fetchMarketplaceListings = async (
  page: number,
  pageSize: number,
  collectionType: string,
  order: IMarketplaceListingOrdering,
  name: string,
): Promise<IMarketplaceListing[] | null> => {
  const responseData = await fetchData(marketplaceListingsDataQuery, { page, pageSize, collectionType, order, name });
  return responseData?.marketplaceListings?.records ?? null;
};

export const fetchSubscriptionPrices = async (collections: string[]): Promise<ISubscriptionPrice[] | null> => {
  const responseData = await fetchData(subscriptionPriceQuery, {
    collections,
  });
  return responseData?.subscriptionManagerPrices ?? null;
};

export const fetchAssociatedEventForExpass = async (expass: string): Promise<EventDetails[] | []> => {
  const responseData = await fetchData(expassEventsDataQuery, {
    expass,
  });
  return responseData?.linkedEventsOfExpass ?? [];
};

import {
  IPFS_URL,
  IPNS_URL,
  IPNS_UPLOAD_LAMBDA_URL,
  FRIENDS_IPNS_FILE_NAME,
  MASTER_KEY,
  PUBSUB_URL,
  SOCIAL_MEDIA_SIGNER,
  API_REQUEST_TIMEOUT,
  USERDETAIL_IPNS_FILE_NAME,
  SOCIAL_MEDIA_API,
} from '../appconstants';
import { LogCustomError } from '../helpers/AppLogger';
import base64 from 'react-native-base64';
import { ethers, Wallet } from 'ethers';
import { Buffer } from 'buffer';
import { separator } from './Messages';
import { IIPNSUserDetailType } from '../interfaces/IUser.interface';
import { LogToLoot8Console } from './Loot8ConsoleLogger';
import { getSyncedTime } from './DateHelper';

export const fetchWithTimeout = async (
  resources,
  options,
  timeout?: number,
) => {
  // pull request timout from config
  const apiTimeout = API_REQUEST_TIMEOUT();

  let _timeout = 1 * 60 * 1000;
  if (apiTimeout) {
    _timeout = Number(apiTimeout);
  }
  if (timeout) {
    _timeout = timeout;
  }

  // initilize abort controller
  const controller = new AbortController();

  // append signal parameter with request
  const params = { ...options, signal: controller.signal };

  // set timeout
  const timeoutId = setTimeout(() => controller.abort(), _timeout);

  // call api to pull the data
  const response = await fetch(resources, params);

  // clear timeout
  clearTimeout(timeoutId);

  // return api response
  return response;
};

export const getIPFSLink = (
  path: string,
  directPath: boolean = false,
): string => {
  if (directPath && path?.indexOf?.('https') > -1) return path;
  return IPFS_URL() + 'ipfs/' + path.replace(/ipfs:\/\//g, '');
};

export const getIPNSLink = (path: string): string => {
  return IPNS_URL() + path.replace(/ipns:\/\//g, '');
};

export const wait = timeout => {
  return new Promise(resolve => setTimeout(resolve, timeout));
};

const fetchPlus = (url, options = {}, retries, _timeout?: number) =>
  fetchWithTimeout(url.trim(), options, _timeout)
    .then(async res => {
      if (res.ok) {
        return res;
      }
      if (retries > 0) {
        //* Invalid request, Avoid retries
        if (res.status === 400) {
          throw new Error(res.status + ':' + JSON.stringify(res));
        }
        LogToLoot8Console('waiting..');
        await wait(5000);
        LogToLoot8Console('restries', url, retries);
        return fetchPlus(url, options, retries - 1);
      }
      throw new Error(res.status + ':' + JSON.stringify(res));
    })
    .catch(async error => console.error(error.message));

export const getIPFSData = async (
  path: string,
  timeout?: number,
  directPath: boolean = false,
): Promise<Response> => {
  return await fetchPlus(
    directPath ? path : getIPFSLink(path),
    {
      method: 'GET',
    },
    3,
    timeout,
  );
};

export const publishMessageToIPFSTopic = async (
  topic: string,
  wallet: string,
  message: string,
  signature: string,
) => {
  const formData = new FormData();
  formData.append(
    'data',
    JSON.stringify({ topic: wallet, message, signature }),
  );
  // Why is string.replaceAll() not a function on Android React Native?
  // https://stackoverflow.com/questions/69297024/why-is-string-replaceall-not-a-function-on-android-react-native
  let encodedTopic = 'u' + base64.encode(topic);
  encodedTopic =
    encodedTopic.indexOf('=') > -1
      ? encodedTopic.split('=').join('')
      : encodedTopic;

  return fetch(PUBSUB_URL() + '/api/v0/pubsub/pub?arg=' + encodedTopic, {
    method: 'POST',
    body: formData,
    headers: { Host: PUBSUB_URL().replace('https://', '') },
  })
    .then(response => {
      if (response.status == 200) return response;
      return null;
    })
    .catch(err => {
      console.error(err);
    });
};

export const subscribeToIPFSPubSub = (
  topic: string,
  callbackFn: any,
  isSocialMedia = false,
) => {
  var http = new XMLHttpRequest();
  // Why is string.replaceAll() not a function on Android React Native?
  // https://stackoverflow.com/questions/69297024/why-is-string-replaceall-not-a-function-on-android-react-native
  let encodedTopic = 'u' + base64.encode(topic);
  encodedTopic =
    encodedTopic.indexOf('=') > -1
      ? encodedTopic.split('=').join('')
      : encodedTopic;

  var url = PUBSUB_URL() + '/api/v0/pubsub/sub?arg=' + encodedTopic;
  http.open('POST', url, true);

  //Send the proper header information along with the request
  //http.setRequestHeader("Host", PUBSUB_URL().replace("https://", ""));

  http.onerror = function (event) {
    http.open('POST', url, true);
    http.send(); //Resend subscrpition.
  };

  http.onreadystatechange = function () {
    if (
      http.status == 200 &&
      http.responseText &&
      http.responseText.length > 0
    ) {
      const allMessages = http.responseText.split('\n');

      let message: string = '';
      try {
        message = JSON.parse(allMessages[allMessages.length - 2]).data;
      } catch (error) {
        LogToLoot8Console('http receive hook - JSON Parse Error: ');
        LogToLoot8Console(error);
        LogCustomError('http receive hook', 'JSON Parse Error', error, '');
        throw error;
      }

      let encodedData;
      try {
        encodedData = base64.decode(message.substring(1, message.length));
      } catch (error) {
        LogToLoot8Console('http receive hook - Base 64 decode error');
        LogToLoot8Console(error);
        LogCustomError('http receive hook', 'Base 64 decode error', error, '');
        throw error;
      }

      let jsonData: any = {};
      if (encodedData) {
        encodedData =
          encodedData.indexOf('\x00') > -1
            ? encodedData.split('\x00').join('')
            : encodedData;
        try {
          jsonData = JSON.parse(encodedData);
        } catch (error) {
          LogToLoot8Console(
            'http receive hook - Encoded data JSON Parse error',
          );
          LogToLoot8Console(error);
          LogCustomError(
            'http receive hook',
            'Encoded data JSON Parse error',
            error,
            '',
          );
          throw error;
        }

        let sig: any;
        try {
          if (isSocialMedia) {
            const currentTime = getSyncedTime();
            if (
              currentTime - 10000 <= jsonData.timestamp &&
              jsonData.timestamp <= currentTime + 10000
            ) {
              sig = ethers.utils.verifyMessage(
                getBufferForSigningSocialMessage(
                  jsonData.timestamp,
                  jsonData.event,
                  getMessageNotificationBuffer(jsonData.data),
                ),
                jsonData.signature,
              );
            }
          } else {
            sig = ethers.utils.verifyMessage(
              jsonData.message,
              jsonData.signature,
            );
          }
        } catch (error) {
          LogToLoot8Console('http receive hook - Signature Error');
          LogToLoot8Console(error);
          LogCustomError('http receive hook', 'Signature Error', error, '');
          throw error;
        }

        if (isSocialMedia && sig && sig.toLowerCase() == SOCIAL_MEDIA_SIGNER) {
          callbackFn(jsonData);
        } else if (sig == jsonData.topic) {
          callbackFn(jsonData.topic, jsonData.message);
        } else {
          LogToLoot8Console('http receive hook - Signature Mismatch');
          LogCustomError(
            'http receive hook',
            'Signature Mismatch',
            'Topic Mismatch',
            '',
          );
        }
      }
    }
  };
  http.send();
  return http;
};

const getMessageNotificationBuffer = (notification: any): Buffer => {
  const bufs: (Buffer | string)[] = [];

  bufs.push(notification.feed);
  if (notification.feedId) {
    bufs.push(notification.feedId);
  }
  bufs.push(notification.timestamp.toString());
  bufs.push(notification.hash);
  bufs.push(notification.parent ?? '');
  bufs.push(notification.messageId);
  bufs.push(notification._type);
  bufs.push(notification.sender);

  return getBufferForSigningSocialMessage(...bufs);
};

const getBufferForSigningSocialMessage = (...args: any): Buffer => {
  const chunks: Buffer[] = [];

  for (let i = 0; i < args.length; i++) {
    if (i > 0) chunks.push(separator);

    if (args[i] instanceof Buffer) {
      chunks.push(args[i] as Buffer);
    } else {
      chunks.push(Buffer.from(args[i].toString(), 'utf-8'));
    }
  }

  return Buffer.concat(chunks);
};

export const publishMessageForOrderDispatchTopic = async (
  entityAddress: string,
  message: string,
) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify({ message }));
  // Why is string.replaceAll() not a function on Android React Native?
  // https://stackoverflow.com/questions/69297024/why-is-string-replaceall-not-a-function-on-android-react-native
  let encodedTopic = 'u' + base64.encode('loot8-order-' + entityAddress);
  encodedTopic =
    encodedTopic.indexOf('=') > -1
      ? encodedTopic.split('=').join('')
      : encodedTopic;

  return fetch(PUBSUB_URL() + '/api/v0/pubsub/pub?arg=' + encodedTopic, {
    method: 'POST',
    body: formData,
    headers: { Host: PUBSUB_URL().replace('https://', '') },
  })
    .then(response => {
      if (response.status == 200) return response;
      return null;
    })
    .catch(err => {
      console.error(err);
    });
};

export const subscribeToOrderDeliverIPFS = (friend, callbackFn: any) => {
  var http = new XMLHttpRequest();
  // Why is string.replaceAll() not a function on Android React Native?
  // https://stackoverflow.com/questions/69297024/why-is-string-replaceall-not-a-function-on-android-react-native
  let encodedTopic = 'u' + base64.encode('loot8-order-all');
  encodedTopic =
    encodedTopic.indexOf('=') > -1
      ? encodedTopic.split('=').join('')
      : encodedTopic;
  LogToLoot8Console('encodedTopic', encodedTopic);
  var url = PUBSUB_URL() + '/api/v0/pubsub/sub?arg=' + encodedTopic;
  http.open('POST', url, true);

  //Send the proper header information along with the request
  //http.setRequestHeader("Host", PUBSUB_URL().replace("https://", ""));

  http.onreadystatechange = function () {
    if (
      http.status == 200 &&
      http.responseText &&
      http.responseText.length > 0
    ) {
      const allMessages = http.responseText.split('\n');
      LogToLoot8Console('allMessages', allMessages);
      let message: string = JSON.parse(
        allMessages[allMessages.length - 2],
      ).data;
      let encodedData = base64.decode(message.substring(1, message.length));
      if (encodedData) {
        encodedData =
          encodedData.indexOf('\x00') > -1
            ? encodedData.split('\x00').join('')
            : encodedData;
        const jsonData = JSON.parse(encodedData);
        if (
          ethers.utils.verifyMessage(jsonData.message, jsonData.signature) ==
          friend.wallet
        ) {
          callbackFn(friend, jsonData.message);
        }
      }
    }
  };
  http.send();
};

// export const uploadFriendsDataToMFS = async (userWallet: Wallet, friendsJson) => {
//   const data = Buffer.from(JSON.stringify(friendsJson), 'utf-8');
//   const canonicalizedData = getBufferForSigning(data, FRIENDS_IPNS_FILE_NAME);
//   const lambda_signature = await userWallet.signMessage(canonicalizedData);

//   const masterWallet = new ethers.Wallet(MASTER_KEY);
//   const masterSignature = await masterWallet.signMessage(lambda_signature);

//   let ipnsURL;

//   ipnsURL = fetch(
//     IPNS_UPLOAD_LAMBDA_URL +
//       'user/' +
//       userWallet.address +
//       '/secure-upload/raw/' +
//       FRIENDS_IPNS_FILE_NAME +
//       '/' +
//       lambda_signature +
//       '/' +
//       masterSignature,
//     {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(friendsJson),
//     },
//   )
//     .then(async response => {
//       if (response.status == 200) {
//         const ipns = JSON.parse(await response.text()).folderIPNS;
//         return ipns !== '';
//       }
//       return false;
//     })
//     .catch(err => {
//       LogToLoot8Console('Upload Friends Following Error');
//       console.error(err);
//     });

//   return ipnsURL && ipnsURL !== '';
// };

export const uploadUserStatusMFS = async (
  userWallet: Wallet,
  status: string,
) => {
  const filename = 'status.txt';
  const data = Buffer.from(status, 'utf-8');
  const canonicalizedData = getBufferForSigning(data, filename);
  const lambda_signature = await userWallet.signMessage(canonicalizedData);

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(lambda_signature);

  const postStatusURL =
    IPNS_UPLOAD_LAMBDA_URL +
    'user/' +
    userWallet.address +
    '/secure-upload/raw/' +
    filename +
    '/' +
    lambda_signature +
    '/' +
    masterSignature;

  fetch(postStatusURL, {
    method: 'POST',
    headers: { 'Content-Type': 'text/plain' },
    body: status,
  }).catch(err => {
    LogToLoot8Console('Upload User Status Error');
    console.error(err);
  });
};

export const getUserIPNSURL = async (userWallet: Wallet) => {
  const blankFriendsJSON = { friends: [] };
  const data = Buffer.from(JSON.stringify(blankFriendsJSON), 'utf-8');
  const canonicalizedData = getBufferForSigning(data, FRIENDS_IPNS_FILE_NAME);
  const lambda_signature = await userWallet.signMessage(canonicalizedData);

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(lambda_signature);

  let ipnsURL;
  ipnsURL = retryFetch(() =>
    fetch(
      IPNS_UPLOAD_LAMBDA_URL +
        'user/' +
        userWallet.address +
        '/secure-upload/raw/' +
        FRIENDS_IPNS_FILE_NAME +
        '/' +
        lambda_signature +
        '/' +
        masterSignature,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(blankFriendsJSON),
      },
    ).then(async response => {
      if (response.status == 200) {
        const ipns = JSON.parse(await response.text()).folderIPNS;
        return ipns !== '';
      }
      return false;
    }),
  ).catch(err => {
    LogToLoot8Console('fetch error for User IPNS');
    console.error(err);
  });
  return ipnsURL && ipnsURL !== '';
};

export const uploadUserData = async (
  newAvatarUri: string,
  userWallet: Wallet,
  filename = 'avatar.txt',
) => {
  // const filename = 'avatar.txt';
  const data = Buffer.from(newAvatarUri, 'utf-8');
  const canonicalizedData = getBufferForSigning(data, filename);
  const lambda_signature = await userWallet.signMessage(canonicalizedData);

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(lambda_signature);

  //post new avatar URI to IPFS
  let ipnsAvatarUri;
  const postAvatarURL =
    IPNS_UPLOAD_LAMBDA_URL +
    'user/' +
    userWallet.address +
    '/secure-upload/raw/' +
    filename +
    '/' +
    lambda_signature +
    '/' +
    masterSignature +
    '?ipns=false';

  ipnsAvatarUri = retryFetch(() =>
    fetch(postAvatarURL, {
      method: 'POST',
      headers: { 'Content-Type': 'text/plain' },
      body: newAvatarUri,
    }).then(async res => {
      if (res.status == 200) {
        const cid = JSON.parse(await res.text()).fileCID;
        ipnsAvatarUri = 'ipfs://' + cid;
        return ipnsAvatarUri;
      }
    }),
  ).catch(err => {
    LogToLoot8Console('fetch error for User Avatar');
    LogCustomError('uploadUserData', err.name, err.message, err.stack);
    console.error(err);
  });
  return ipnsAvatarUri;
};

export const getUserDetailsFromIPFS = async (avatarURI): Promise<any> => {
  let data;
  if (avatarURI) {
    data = retryFetch(() =>
      fetchWithTimeout(
        IPFS_URL() + 'ipfs/' + avatarURI.replace('ipfs://', '') + '/',
        { method: 'GET' },
      ),
    )
      .then(async response => {
        if (response.status == 404) {
          return '';
        } else {
          return await response.text();
        }
      })
      .catch(error => LogToLoot8Console(error));
  }
  return data;
};

export const retryFetch = (
  fn,
  retriesLeft = 3,
  interval = 1000,
): Promise<Response> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retryFetch(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const getUserJSONdataFromFile = async (
  ipnsURL,
  fileName: string,
): Promise<Response> => {
  let data;
  if (ipnsURL) {
    data = retryFetch(() =>
      fetchWithTimeout(
        IPNS_URL() + ipnsURL.replace('ipns://', '') + '/' + fileName,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
      ),
    )
      .then(response => {
        return response.json();
      })
      .catch(error => LogToLoot8Console(error));
  }
  return data;
};

export const getFriendsStatusIPNS = async (ipnsURL): Promise<string> => {
  let data;
  if (ipnsURL) {
    data = retryFetch(() =>
      fetchWithTimeout(
        IPNS_URL() + ipnsURL.replace('ipns://', '') + '/status.txt',
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/text' },
        },
      ),
    )
      .then(async response => {
        if (response.status == 200) {
          return await response.text();
        } else {
          return '';
        }
      })
      .catch(error => LogToLoot8Console(error));
  }
  return data;
};

export const getUserJSONdata = async (
  address: string | string[],
): Promise<any> => {
  let response;
  if (address) {
    const body = Array.isArray(address) ? address : [address.toLowerCase()];
    const timestamp = getSyncedTime();
    response = await retryFetch(() =>
      fetchWithTimeout(SOCIAL_MEDIA_API + 'api/v1/account/details/', {
        method: 'POST',
        headers: {
          'X-Loot8-Timestamp': timestamp?.toString(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accounts: body }),
      }),
    )
      .then(async response => {
        return await response.json();
      })
      .catch(error => LogToLoot8Console(error));
  }

  return Array.isArray(address)
    ? response?.data
    : response?.data[address?.toLowerCase()];
};

export const getBufferForSigning = (fileData: Buffer, fileName: string) => {
  const separator: Buffer = Buffer.from([0x01]);
  const chunks: Buffer[] = [];
  chunks.push(Buffer.from(fileName.trim(), 'utf-8'));
  chunks.push(separator);
  chunks.push(fileData);

  return Buffer.concat(chunks);
};

export const getIPNSData = async (ipnsCID, fileName): Promise<string> => {
  let data;
  if (ipnsCID && fileName) {
    data = retryFetch(() =>
      fetchWithTimeout(
        IPNS_URL() + ipnsCID.replace('ipns://', '') + '/' + fileName,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/text' },
        },
      ),
    )
      .then(async response => {
        if (response.status == 200) {
          return await response.text();
        } else {
          return '';
        }
      })
      .catch(error => LogToLoot8Console(error));
  }
  return data;
};

export const uploadUserDetailsToMFS = async (
  userDetails: IIPNSUserDetailType,
  userWallet: Wallet,
) => {
  const data = Buffer.from(JSON.stringify(userDetails), 'utf-8');
  const canonicalizedData = getBufferForSigning(
    data,
    USERDETAIL_IPNS_FILE_NAME,
  );
  const lambda_signature = await userWallet.signMessage(canonicalizedData);

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(lambda_signature);

  return retryFetch(() =>
    fetch(
      IPNS_UPLOAD_LAMBDA_URL +
        'user/' +
        userWallet.address +
        '/secure-upload/raw/' +
        USERDETAIL_IPNS_FILE_NAME +
        '/' +
        lambda_signature +
        '/' +
        masterSignature,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userDetails),
      },
    ).then(async response => {
      if (response.status == 200) {
        const ipns = JSON.parse(await response.text()).folderIPNS;
        return ipns;
      }
      return null;
    }),
  ).catch(err => {
    LogToLoot8Console('fetch error for User IPNS');
    console.error(err);
  });
};

export const isValidIpfsUrl = url => {
  // Check for ipns/ in the URL (indicating dynamic IPFS content)
  if (url.includes('/ipns/')) {
    return true; // This is an IPNS URL, referring to dynamic content
  }

  // Check if it's a valid static IPFS URL with a hash (CID)
  const cidString = url.split('/ipfs/')[1];

  if (!cidString) return false; // No CID part found

  // Check if the CID length is either 46 (CIDv0) or between 56 and 59 (CIDv1)
  return (
    cidString.length === 46 ||
    (cidString.length >= 56 && cidString.length <= 59)
  );
};
